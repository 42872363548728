<template>
  <div>

    <div v-if="AuthState.loading">Loading...</div>
    <div v-if="!AuthState.isAuthenticated">
      <button v-on:click.prevent="login">Login</button>
    </div>
    <div v-else>
      <div v-if="AuthState.isAuthenticated">
        <button v-on:click.prevent="logout">Logout</button>


        <admin-messages-comp></admin-messages-comp>
      </div>

    </div>
  </div>
</template>

<script>
import {useAuth0, AuthState} from "@/auth/useAuth0";
import {onMounted} from "vue";
import AdminMessagesComp from "@/components/adminMessagesComp/adminMessagesComp";


export default {
  name: "loginAdmin",
  components: {AdminMessagesComp},
  setup() {
    const {login, logout, initAuth} = useAuth0(AuthState)
    onMounted(() => {
      initAuth()
    })

    return {
      login,
      logout,
      useAuth0,
      AuthState,
    }
  }
}

</script>

<style scoped>

</style>
