<template>
<div>
  <p v-for="message in messages" :key="message._id">{{message.name}} {{message.text}}</p>
</div>
</template>

<script>
import axios from "axios";
import {AuthState} from "@/auth/useAuth0";


export default {
  name: "adminMessagesComp",
  data(){
    return {
      messages: [],
    }
  },
  mounted() {
    this.getMessages()
  },
  methods:{
    async getMessages(){
        console.log(AuthState)
        const token = await AuthState.token || null

        const config = {
          headers: {
            'Content-Type':'application/json',
            Authorization: `Bearer ${token}`,

          }
        }
      try {

        const {data} = await axios.get('https://portfolio-express-vic.herokuapp.com/authorized', config)
        console.log(data)
        this.messages = data
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
